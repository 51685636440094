/* Fix while we do import DSFR checkbox component. Prevents checkboxes from disappearing. */
input,
select,
textarea {
  appearance: auto;
  -webkit-appearance: auto;
  -moz-appearance: auto;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="number"],
input[type="tel"],
input[type="search"] textarea,
select,
.vTextField {
  border: 0;
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 0.5rem 1rem;
  margin-top: 0;
  color: var(--text-default-grey);
  background-color: var(--background-contrast-grey);
}

.submit-row .deletelink-box a.deletelink {
  height: auto;
}

a.related-widget-wrapper-link,
.add-row a,
a.change-related,
#site-name a,
a.inline-deletelink,
a#logout-link,
.datetimeshortcuts a,
#nav-sidebar a,
th .text a {
  background-image: none;
}

.fr-footer__partners a {
  background: none;
}

table input.fr-input[type="checkbox"] {
  width: auto;
}

.module h2 {
  font-size: 20px;
  background-color: unset;
  color: var(--text-title-grey);
}

.mb-0 {
  margin-bottom: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.align-right {
  text-align: right;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}

.flex-container---end {
  display: flex;
  justify-content: end;
}

.flex-flow--wrap {
  flex-flow: wrap;
}

.overflow-auto {
  overflow: auto;
}

:root {
  --blue-france: #000091;
  --blue-france-300: #9a9aff;
  --blue-france-hover: #1414b2;
  --black-euphro: #383838;

  --primary: var(--blue-france);

  --body-bg: var(--background-default-grey);

  --header-bg: var(--background-default-grey);
  --header-color: var(--text-title-grey);

  --button-bg: var(--background-active-blue-france);
  --button-hover-bg: var(--blue-france-main-525);
  --link-fg: var(--primary);
  --default-button-bg: var(--blue-france-300);
  --default-button-hover-bg: var(--blue-france-hover);

  --grey-100: #161616;
  --grey-425: #666;
  --grey-1000: #f8f8f8;
}

body {
  font-family: "Marianne", "Roboto", "Lucida Grande", "DejaVu Sans",
    "Bitstream Vera Sans", Verdana, Arial, sans-serif;
  background-color: var(--background-alt-grey) !important;
}

select {
  height: auto;
}

input[type="button"]:not(:disabled):hover,
input[type="image"]:not(:disabled):hover,
input[type="reset"]:not(:disabled):hover,
input[type="submit"]:not(:disabled):hover {
  --hover: var(--background-action-high-blue-france-hover);
}

button.fr-link:hover,
button.fr-tabs__tab:hover {
  --hover: var(--background-default-grey-hover);
}

button.fr-btn--tertiary:hover,
button.fr-btn--secondary:hover {
  --hover: inherit;
}

.button,
input[type="submit"],
input[type="button"],
.submit-row input,
a.button {
  border-radius: 0;
}

a:link,
a:visited {
  color: inherit;
}
a.fr-btn {
  color: var(--text-inverted-blue-france);
}
a.fr-btn.fr-btn--secondary {
  color: var(--text-action-high-blue-france);
}

#content-main {
  float: none;
}

.content h1 {
  font-size: 1.5rem;
  line-height: 2rem;
}

#branding h1,
#branding h1 a:link,
#branding h1 a:visited {
  color: var(--text-title-grey);
  font-weight: bold;
}

#container {
  height: auto;
  min-height: 100%;
}

#user-tools {
  font-weight: 400;
}
#user-tools a:link,
#user-tools a:visited {
  color: var(--text-title-grey);
}
#user-tools #logout-link {
  color: var(--delete-button-bg);
}

.module caption {
  background-color: var(--background-default-grey);
}
.module caption a.section:link,
.module caption a.section:visited {
  color: var(--text-title-grey);
}
.module caption th {
  font-weight: 400;
}

tr:nth-child(2n + 1),
.row-form-errors {
  background: var(--darkened-bg);
}
tr:nth-child(2n),
tr:nth-child(2n) .errorlist,
tr:nth-child(2n + 1) + .row-form-errors,
tr:nth-child(2n + 1) + .row-form-errors .errorlist {
  background: var(--body-bg);
}

.inline-group h2 {
  text-transform: inherit;
}

ul.messagelist li {
  cursor: pointer;
}
ul.messagelist li.info {
  background-color: var(--background-contrast-grey);
}
ul.messagelist li span.close {
  float: right;
  font-weight: 800;
  color: var(--body-quiet-color);
  cursor: pointer;
}

label.required:after {
  content: " *" !important;
}

.change-list .object-tools {
  margin-top: -128px;
}

#changelist .actions {
  padding: 0 !important;
}

#changelist-filter h3 {
  font-size: 0.9rem;
}

#changelist-filter li.selected {
  border-left: none !important;
}

.project-tabs {
  margin-bottom: 30px;
}

.project-tabs ul {
  display: flex;
  padding: 0;
  border-bottom: 1px solid var(--border-default-grey);
}
.project-tabs li {
  list-style-type: none;
  margin-right: 1rem;
}
.project-tabs a {
  font-size: 1.15rem;
  color: var(--text-title-grey);
  background-image: none;
}

.project-tabs a:hover {
  color: var(--text-active-blue-france);
}

.project-tabs li.active a {
  color: var(--text-active-blue-france);
  font-weight: bold;
}

.project-tabs span.underline {
  height: 2px;
  width: 100%;
  background-color: var(--text-active-blue-france);
  display: block;
  margin-top: 5px;
}

ul li {
  list-style-type: none;
}

.aglae-object-tools {
  display: flex;
  justify-content: end;
}

.fr-header .fr-btns-group {
  align-items: baseline;
}

/* purgecss ignore */
#toolbar input#searchbar {
  height: 1.93rem;
}

/* purgecss start ignore */
.fr-tag.to_schedule {
  background: var(--background-contrast-yellow-tournesol);
}
.fr-tag.scheduled {
  background: var(--background-contrast-red-marianne);
}
.fr-tag.ongoing {
  background: var(--background-contrast-green-bourgeon);
}
.fr-tag.finished {
  background: var(--background-contrast-blue-ecume);
}
.fr-tag.data_available {
  background: var(--background-contrast-purple-glycine);
}

.delete-confirmation form .button.cancel-link {
  height: unset;
}

.submit-row {
  background: none !important;
  border: none !important;
  justify-content: right;
}

.submit-row .delete-btn {
  color: var(--text-default-error) !important;
  box-shadow: inset 0 0 0 1px var(--text-default-error);
}

.workplace .fr-tabs__panel {
  background-color: var(--background-default-grey);
}

